<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selected.length > 0">
          <v-btn icon left small @click="selected = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selected.length }} Selected
          </span>
        </v-chip>
        <span class="bold_text-1" v-else>Usuarios {{empresaNombre}}</span>
      </template>
      <template v-slot:action>
        <v-btn color="white" fab v-if="selected.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <v-btn color="primary" fab v-else @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%">
              <v-checkbox
                class="center"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
            </th>
            <th class="text-left"><span class="normal_text-1">Usuario</span></th>
            <th class="text-left"><span class="normal_text-1">Correo electrónico</span></th>
            <th class="text-left"><span class="normal_text-1">Teléfono</span></th>
            <th class="text-left"><span class="normal_text-1">Estado</span></th>
            <th class="text-left">
              <span class="normal_text-1">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="usuarios.length == 0">
            <td colspan="6" class="text-center">
                <span class="normal_text darken-2--text">** No hay usuarios **</span>
            </td>
          </tr>
          <tr v-for="(item, index) in usuarios" :key="index">
            <td>
              <v-checkbox
                color="primary"
                v-model="selected"
                :value="item.usuarioId"
              ></v-checkbox>
            </td>
            <td>
              <div class="d-flex">
                <IconWithBackground
                  :color="'blue-grey darken-2'"
                  :icon="'mdi-account'"
                  :bgColor="'#ECEFF1'"
                />
                <div>
                  <span class="normal_text darken-2--text"
                    >{{ item.usuario }}</span
                  >
                  <span class="text-2 blue-grey--text">{{ item.empresaNombre }}</span>
                </div>
              </div>
            </td>
            <td>{{ item.correoElectronico }}</td>
            <td>{{ item.telefono }}</td>
            <td>
              <v-chip
                class="ma-2 white--text"
                color="blue accent-4"
                v-if="item.estadoId == 1"
              >
                {{item.estadoNombre}}
              </v-chip>
              <v-chip class="ma-2" color="blue-grey lighten-5" v-else>
                {{item.estadoNombre}}
              </v-chip>
            </td>
            <td>
              <v-menu bottom left offset-x rounded="xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="editItem(item.usuarioId)">
                      <v-list-item-icon>
                        <v-icon> mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item v-if="item.estadoId == 5" @click="confirm(item.usuarioId, 1)">
                      <v-list-item-icon>
                        <v-icon> mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Aprobar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item v-if="item.estadoId == 2" @click="confirm(item.usuarioId, 1)">
                      <v-list-item-icon>
                        <v-icon> mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Activar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="item.estadoId != 2" @click="confirm(item.usuarioId,2)">
                      <v-list-item-icon>
                        <v-icon> mdi-close</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Desactivar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="confirm(item.usuarioId, 3)">
                      <v-list-item-icon>
                        <v-icon> mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{dialogTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    label="Usuario"
                    v-model="formData.usuario"
                  ></v-text-field>
                </v-col>
                 <v-col cols="12" class="pb-0 pt-0">
                  <v-text-field
                    v-if="formData.usuarioId==0"
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.password"
                    type="password"
                    label="Password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0" v-if="id==null">
                  <v-select
                    :items="empresas"
                    label="Empresas"
                    item-value="empresaId"
                    item-text="empresaNombre"
                    outlined
                    v-model="formData.empresaId"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.correoElectronico"
                    label="Correo electrónico"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" class="pb-0 pt-0">
                  <span class="text-1 blue-grey--text">Gender</span>
                  <v-radio-group
                    v-model="formData.gender"
                    row
                    :rules="[rules.required]"
                  >
                    <v-radio value="F" class="custom_radio">
                      <template v-slot:label>
                        <div class="text-1 blue-grey--text">Female</div>
                      </template>
                    </v-radio>
                    <v-radio value="M" class="custom_radio">
                      <template v-slot:label>
                        <div class="text-1 blue-grey--text">Male</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col> -->
                <v-col cols="12" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.telefono"
                    label="Teléfono"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <!-- <v-select
                    :items="roles"
                    label="Rol"
                    item-value="rolId"
                    item-text="rolNombre"
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.empresaId"
                  ></v-select> -->
                  <v-select
                    v-model="formData.roles"
                    :items="roles"
                    item-text="rolNombre"
                    item-value="rolId"
                    label="Roles"
                    multiple
                    outlined
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" class="pb-0 pt-0">
                  <v-checkbox value="true" v-model="formData.disabled">
                    <template v-slot:label>
                      <div class="text-1 blue-grey--text">Disabled</div>
                    </template>
                  </v-checkbox>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

     <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          &iquest;Estás seguro?
        </v-card-title>
        <v-card-text>&iquest;Seguro que desea realizar esta acción a este usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="actualizar()"
          >
            Aprobar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import { mapState } from "vuex";

export default {
  name: "Users",
  data: () => ({
    formDialog: false,
    dialogTitle:"",
    id:null,
    dialog:false,
    usuarioId:null,
    estadoId:null,
    formData: {
      usuarioId: 0,
      usuario: "",
      EstadoId: "",
      password: "",
      correoElectronico: "",
      Telefono: null,
      EstadoId: 1,
      roles : []
    },
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    selected: [],
  }),
  methods: {
    addItem() {
      let vm = this;

      vm.dialogTitle = "Agregar usuario"

      vm.formData = {
        usuarioId: 0,
        usuario: "",
        estadoId: 0,
        password: "",
        correoElectronico: "",
        telefono: "",
        empresaId: vm.id?vm.id:0,
        roles:[]
      };
      vm.formDialog = true;
    },
    confirm(id, estadoId){
        this.usuarioId = id;
        this.estadoId = estadoId;
        this.dialog = true;
    },
    actualizar(){
      let vm = this;

      let data = {
        id : vm.usuarioId,
        estadoId : vm.estadoId
      };

      vm.$store.dispatch("Actualizar_Estado_Usuario", data).then((d) => {
        if (d.success) {
          vm.getAllUser(vm.id);
          vm.dialog = false;
          vm.usuarioId = null;
        }
      });
    },
    editItem(id) {
      let vm = this;

      vm.dialogTitle = "Editar usuario";
      vm.getData();

      let usuarios = vm.usuarios.find((x) => x.usuarioId === id);
      vm.formData = {
        usuarioId: usuarios.usuarioId,
        usuario: usuarios.usuario,
        estadoId: usuarios.estadoId,
        correoElectronico: usuarios.correoElectronico,
        telefono: usuarios.telefono,
        empresaId:usuarios.empresaId,
        roles: usuarios.roles
      };
      vm.formDialog = true;
    },
    // removeItem(id) {
    //   let vm = this;

    //   let data = {
    //     usuarioId : id,
    //     estadoId : 2
    //   };

    //   vm.$store.dispatch("Actualizar_Estado_Usuario", data).then((d) => {
    //     if (d.success) {
    //       vm.getAllUser(vm.id);
    //       vm.dialog = false;
    //       vm.usuarioId = null;
    //     }
    //   });
    // },
    // eliminar(id) {
    //   let vm = this;

    //   let data = {
    //     usuarioId : id,
    //     estadoId : 3
    //   };

    //   vm.$store.dispatch("Actualizar_Estado_Usuario", data).then((d) => {
    //     if (d.success) {
    //       vm.getAllUser(vm.id);
    //       vm.dialog = false;
    //       vm.usuarioId = null;
    //     }
    //   });
    // },
    // activar(id) {
    //   let vm = this;

    //   let data = {
    //     usuarioId : id,
    //     estadoId : 1
    //   };

    //   vm.$store.dispatch("Actualizar_Estado_Usuario", data).then((d) => {
    //     if (d.success) {
    //       vm.getAllUser(vm.id);
    //       vm.dialog = false;
    //       vm.usuarioId = null;
    //     }
    //   });
    // },
    submit() {
      let vm = this;
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Usuario", vm.formData).then((d) => {
          if (d.success) {
            vm.getAllUser(vm.id);
            vm.formDialog = false;
          }
        });
        vm.formDialog = false;
      }
    },
    getAllUser(id) {
      if(id != undefined || id != null){
        this.$store.dispatch("GET_USUARIOS", id);
      }else{
        this.$store.dispatch("GET_USUARIOS", 0);
      }
    },

    getData(){
      this.$store.dispatch("GET_Empresas_Select");
      this.$store.dispatch("GET_ROLES");
    }

  },
  mounted() {
    this.id = this.$router.currentRoute.query.id;
    
    if(this.id != null){
        this.$store.dispatch("GET_EmpresaById", this.id);
    }
    this.getData();
    this.getAllUser(this.id);
    
  },
  computed: {
    ...mapState(["usuarios","empresas", "roles", "empresa"]),
    empresaNombre:{
       get:function(){
         return this.id == null? "": "- " + this.empresa.nombre;
       }
    },
    selectAll: {
      get: function () {
        return this.usuarios ? this.selected.length == this.usuarios.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.usuarios.forEach(function (user) {
            selected.push(user.usuarioId);
          });
        }

        this.selected = selected;
      },
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>