import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.prototype.$urlBase = "https://www.opos.com.do/OposApi/";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
