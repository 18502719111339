<template >
  <div>
    <app-bar-component>
      <template v-slot:action>
        <v-btn @click="logout" color="white" fab elevation="0">
          <v-icon color="blue-grey darken-2">mdi-exit-to-app</v-icon>
        </v-btn>
      </template>
    </app-bar-component>
    <div class="custom_container">
      <v-row style="align-items: center">
        <v-col
          lg="4"
          xl="4"
          md="4"
          cols="12"
          :class="largeSize() ? 'pr-12' : ''"
        >
          <div class="profile_container text-center mb-12">
            <!-- <div class="mb-6">
              <v-avatar size="200">
                <img
                  contain
                  src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
                  alt="John"
                />
              </v-avatar>
            </div> -->
            <div class="bold_text-2">{{ userName }}</div>
            <div class="text-2">{{ rolesName }}</div>
          </div>
          <!-- <div>
            <card-box-component
              :maxWidth="'auto'"
              :maxHeight="'200px'"
              :clases="'justify-center flex-column'"
            >
              <template v-slot:header>
                <div class="bold_text-2 blue-grey--text text-left">
                  Profile Completion
                </div>
              </template>
              <template v-slot:body>
                <CircularBarComponent
                  :value="user.porcentage"
                  :color="'primary'"
                  :dimension="dimension"
                  :textStyle="{
                    color: '#455A64',
                    fontSize: '30px',
                    fontWeight: '800',
                  }"
                />
              </template>
            </card-box-component>
          </div> -->
        </v-col>
        <v-col lg="8" xl="8" md="8" cols="12">
          <v-tabs
            background-color="blue-grey lighten-5"
            hide-slider
            active-class="custom_tab"
            v-model="tab"
          >
            <!-- <v-tab><span class="normal_text-1 text-none">Activity</span></v-tab> -->
            <v-tab
              ><span class="normal_text-1 text-none">Información</span></v-tab
            >
            <v-tab
              ><span class="normal_text-1 text-none">Contraseña</span></v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- <v-tab-item>
              <v-card flat color="blue-grey lighten-5">
                <v-card-text>
                  <v-timeline align-top dense>
                    <v-timeline-item
                      color="blue-grey lighten-1"
                      fill-dot
                      small
                      v-for="(item, index) in activities"
                      :key="index"
                      ><v-card elevation="0" rounded class="rounded-xl" flat>
                        <div class="pa-6">
                          <div>
                            <span class="text_activity_strong"
                              >{{ item.person }} </span
                            ><span class="text_activity"
                              >{{ item.event }}:
                            </span>
                            <span class="text_activity_strong">{{
                              item.activity
                            }}</span>
                          </div>
                          <div class="mt-2">
                            <span class="text-3">{{ item.time }}</span>
                          </div>
                        </div>
                      </v-card></v-timeline-item
                    >
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-tab-item> -->
            <v-tab-item>
              <v-card flat color="blue-grey lighten-5" class="pt-6">
                <v-form ref="form" @submit.prevent="submit">
                  <v-card-text>
                    <v-card elevation="0" rounded class="rounded-xl">
                      <div class="pa-6">
                        <div class="bold_text-2">Actualizar perfil</div>
                        <div>
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  outlined
                                  :rules="[rules.required]"
                                  label="Nombre de usuario"
                                  v-model="usuarioModel.username"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  outlined
                                  :rules="[rules.required]"
                                  v-model="usuarioModel.email"
                                  label="Correo electrónico"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  outlined
                                  :rules="[rules.required]"
                                  v-model="usuarioModel.telefono"
                                  label="Teléfono"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-select
                                  :items="empresas"
                                  label="Empresas"
                                  item-value="empresaId"
                                  item-text="empresaNombre"
                                  outlined
                                  v-model.number="usuarioModel.empresaId"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <!-- <v-select
                    :items="roles"
                    label="Rol"
                    item-value="rolId"
                    item-text="rolNombre"
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.empresaId"
                  ></v-select> -->
                                <v-select
                                  v-model="usuarioModel.roles"
                                  :items="roles"
                                  item-text="rolNombre"
                                  item-value="rolId"
                                  label="Roles"
                                  multiple
                                  outlined
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text type="submit">
                          <span class="text-none">Actualizar</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat color="blue-grey lighten-5" class="pt-6">
                <v-form ref="form" @submit.prevent="updatePassword">
                  <v-card-text>
                    <v-card elevation="0" rounded class="rounded-xl">
                      <div class="pa-6">
                        <div class="bold_text-2">Cambia tu contraseña</div>
                        <div>
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  outlined
                                  :rules="[rules.required]"
                                  label="Contraseña actual"
                                  type="password"
                                  v-model="passwordModel.oldPassword"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  outlined
                                  :rules="[rules.required]"
                                  type="password"
                                  v-model="passwordModel.newPassword"
                                  label="Nueva contraseña"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  outlined
                                  type="password"
                                  :rules="[rules.required, matchingPasswords]"
                                  v-model="passwordModel.confirmPassword"
                                  label="Confirmar contraseña"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text type="submit">
                          <span class="text-none">Actualizar</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import CardBoxComponent from "../components/Home/CardBoxComponent.vue";
import CircularBarComponent from "../components/Dashboard/CircularBarComponent.vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
export default {
  data: () => ({
    tab: null,
    user: {
      fullName: "John Smith",
      role: "Admin",
      porcentage: 75,
    },
    // formData: {
    //   id: 0,
    //   firstName: "John",
    //   lastName: "Smith",
    //   gender: "M",
    //   email: "johnSmith@domain.com",
    // },
    passwordModel: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    rules: {
      required: (value) => !!value || "Field is required.",
    },
    // activities: [
    //   {
    //     person: "You",
    //     event: "added a new event",
    //     activity: "React Summit",
    //     time: "10 months ago",
    //   },
    //   {
    //     person: "You",
    //     event: " updated event",
    //     activity: "React Summit",
    //     time: "10 months ago",
    //   },
    //   {
    //     person: "You",
    //     event: "deleted user",
    //     activity: "React Summit",
    //     time: "10 months ago",
    //   },
    //   {
    //     person: "You",
    //     event: "updated user",
    //     activity: "React Summit",
    //     time: "10 months ago",
    //   },
    // ],
    dimension: 0,
  }),
  methods: {
    matchingPasswords: function () {
      if (
        this.passwordModel.newPassword === this.passwordModel.confirmPassword
      ) {
        return true;
      } else {
        return "Las contraseñas no coinciden.";
      }
    },
    getData() {
      this.$store.dispatch("GET_Empresas_Select");
      this.$store.dispatch("GET_CURRENT_USER");
      this.$store.dispatch("GET_ROLES");
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/Login").catch(() => {});
      });
    },
    updatePassword() {

      let vm = this;

      let data = {
        oldPassword : vm.passwordModel.oldPassword,
        newPassword : vm.passwordModel.newPassword
      };

      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Change_Password", data).then((d) => {
          if (d.success) {
            vm.passwordModel.oldPassword = "";
            vm.passwordModel.newPassword = "";
            vm.passwordModel.confirmPassword = "";
          }
        });
      }

    },

    resize() {
      let vm = this;
      let xl = 200;
      let lg = 200;
      let md = 150;
      let sm = 150;
      let xs = 150;
      let res = 0;
      let item = vm.$vuetify.breakpoint;
      switch (item.name) {
        case "lg": {
          res = lg;
          break;
        }
        case "md": {
          res = md;
          break;
        }
        case "sm": {
          res = sm;
          break;
        }
        case "xs": {
          res = xs;
          break;
        }
        default:
          {
            res = xl;
          }
          break;
      }
      this.dimension = res;
    },
    largeSize() {
      return (
        this.$vuetify.breakpoint.xl ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.md
      );
    },
    submit() {
      // console.log("hola");
      let vm = this;

      let data = {
        empresaId: vm.usuarioModel.empresaId,
        correoElectronico: vm.usuarioModel.email,
        telefono: vm.usuarioModel.telefono,
        usuario: vm.usuarioModel.username,
        roles : vm.usuarioModel.roles
      };

      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Actualizar_Usuario", data).then((d) => {
          if (d.success) {
          }
        });
        // vm.formDialog = false;
      }
    },
  },
  computed: {
    ...mapState(["empresas", "usuarioModel", "roles"]),
    userName: function () {
      return this.$store.getters.userName;
    },
    rolesName: function () {
      return this.$store.getters.roles;
    },
  },
  mounted() {
    this.getData();
    this.resize();
  },

  components: {
    AppBarComponent,
    CardBoxComponent,
    CircularBarComponent,
  },
};
</script>
<style scoped>
.image-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.custom_tab {
  background-color: white;
  border-radius: 50px;
}

.text_activity,
.text_activity_strong {
  margin: 0px;
  font-family: "Nunito", sans-serif !important;
  letter-spacing: 0px;
  color: #455a64;
}
.text_activity_strong {
  font-weight: 800;
  font-size: 1rem;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}
.v-tabs .v-tab:hover::before {
  border-radius: 50px;
}
</style>