<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selected.length > 0">
          <v-btn icon left small @click="selected = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selected.length }} Selected
          </span>
        </v-chip>
        <span class="bold_text-1" v-else>Lugares</span>
      </template>
      <template v-slot:action>
        <v-btn color="white" fab v-if="selected.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <v-btn color="primary" fab v-else @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%">
              <v-checkbox
                class="center"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Lugar</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lugares.length == 0">
            <td colspan="3" class="text-center">
              ** No hay lugares existentes **
            </td>
          </tr>
          <tr v-for="(item, index) in lugares" :key="index">
            <td>
              <v-checkbox
                color="primary"
                v-model="selected"
                :value="item.lugarId"
              ></v-checkbox>
            </td>
            <td>
              <div class="d-flex" style="align-items: center;">
                <!-- <IconWithBackground
                  :color="'blue-grey darken-2'"
                  :icon="'mdi-account'"
                  :bgColor="'#ECEFF1'"
                /> -->
                <v-avatar>
                  <img
                    :src="$urlBase + 'api/Archivos/Descargar/'+item.lugarArchivoId+'?id='+ random()"
                    
                  />
                </v-avatar>
                <div class="ml-2">
                  <span class="normal_text darken-2--text">{{
                    item.lugarNombre
                  }}</span>
                </div>
              </div>
            </td>
            <td>
              <v-menu bottom left offset-x rounded="xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="editItem(item.lugarId)">
                      <v-list-item-icon>
                        <v-icon> mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0 text-center">
                  <div>
                    <v-avatar size="160">
                      <img contain :src="srcUrl" alt="John" />
                    </v-avatar>
                  </div>
                  <div>
                    <v-btn
                      color="primary"
                      rounded
                      class="mt-1 ml-1"
                      :loading="isSelecting"
                      @click="onButtonClick"
                    >
                      <v-icon class="mr-1"> mdi-cloud-upload </v-icon>
                      {{ buttonText }}
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    label="Lugar"
                    v-model="formData.lugarNombre"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import router from "../router/index";
import { mapState } from "vuex";

export default {
  name: "Lugares",
  data: () => ({
    formDialog: false,
    dialogTitle: "",
    selectedFile: null,
    isSelecting: false,
    src: "",
    formData: {
      lugarId: 0,
      lugarNombre: "",
    },
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    selected: [],
  }),
  methods: {
    addItem() {
      let vm = this;

      vm.dialogTitle = "Agregar lugar";

      vm.selectedFile = null;
      vm.src = "";

      vm.formData = {
        lugarId: 0,
        lugarNombre: "",
      };
      vm.formDialog = true;
    },
    random(){
        return Math.random();
    },
    editItem(id) {
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar lugar";
      vm.getData();

      let e = vm.lugares.find((x) => x.lugarId === id);
      vm.src = this.$urlBase + "api/Archivos/Descargar/" + e.lugarArchivoId;
      vm.formData = {
        lugarId: e.lugarId,
        lugarNombre: e.lugarNombre,
      };
      vm.formDialog = true;
    },
    removeItem() {},
    submit() {
      let vm = this;

      var data = new FormData();
      var json = JSON.stringify(vm.formData);
      data.append("json", json);
      data.append("Image", vm.selectedFile);
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Lugar", data).then((d) => {
          if (d.success) {
            vm.getAllList();
            vm.formDialog = false;
          }
        });
        vm.formDialog = false;
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.src = URL.createObjectURL(this.selectedFile);
    },
    getAllList() {
      this.$store.dispatch("GET_Lugares");
    },

    getData() {
      this.$store.dispatch("GET_ROLES");
      this.$store.dispatch("GET_Planes");
      this.$store.dispatch("GET_Comprobantes");
    },
  },
  mounted() {
    this.getData();
    this.getAllList();
  },
  computed: {
    ...mapState(["lugares", "planes", "comprobantes"]),
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.nombreBtn;
    },
    srcUrl() {
      return this.src
        ? this.src
        : require("../assets/Imagen no disponible.png");
    },
    selectAll: {
      get: function () {
        return this.lugares
          ? this.selected.length == this.lugares.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.lugares.forEach(function (emp) {
            selected.push(emp.lugarId);
          });
        }

        this.selected = selected;
      },
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>