<template>
  <v-container>
    <app-bar-component>
      <template v-slot:action>
        <v-menu bottom left offset-y min-width="360" rounded="xl">
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="red" dot overlap>
              <v-btn icon v-bind="attrs" v-on="on" large>
                <v-icon>mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-card class="rounded-xl pa-4">
            <div>
              <v-list class="pa-0" rounded>
                <v-list-item-group color="#455a64">
                  <v-list-item
                    link
                    v-for="(item, index) in notifications"
                    :key="index"
                  >
                    <v-list-item-avatar size="45">
                      <img :src="item.url" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-1">{{
                          item.title
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle
                        ><span class="text-2">{{
                          item.subtitle
                        }}</span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-btn
                color="#ECEFF1"
                block
                x-large
                class="rounded-lg"
                height="60"
              >
                <span class="text-1 text-none font-weight-bold">
                  See all notifications
                </span>
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </app-bar-component>
    <v-row>
      <v-col col="12" md="6" lg="4">
        <WelcomeComponent />
        <AchievementComponent />
      </v-col>

      <v-col col="12" md="6" lg="4">
        <InlineCardComponent
          :element="item"
          v-for="(item, index) in data"
          :key="index"
        />
        <card-box-component
          :maxWidth="'auto'"
          :maxHeight="'223px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="subtitle pb-4 text-center">Views</div>
            <div class="bold_text-1 blue-grey--text text-center">6.967.431</div>
          </template>
          <template v-slot:body>
            <LineChart :id="1" :chartdata="chartData" :options="chartOptions" />
          </template>
          <template v-slot:action>
            <div class="mt-10">
              <inline-card-component :element="childObject">
                <template v-slot:action>
                  <v-btn icon>
                    <v-icon color="#455a64">mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
              </inline-card-component>
            </div>
          </template>
        </card-box-component>
      </v-col>

      <v-col col="12" md="6" lg="4">
        <card-box-component :clases="'justify-center flex-column'">
          <template v-slot:header>
            <div class="bold_text-2">Targets</div>
          </template>
          <template v-slot:body>
            <div class="pt-6">
              <ProgressLinearComponent
                :title="item.title"
                :color="item.color"
                :value="item.value"
                v-for="(item, index) in linearBar"
                :key="index"
              />
            </div>
          </template>
        </card-box-component>

        <div class="bold_text-1 mb-6" style="font-size: 1.3rem">Meetings</div>

        <InlineCardComponent
          :element="item"
          v-for="(item, index) in meetings"
          :key="index"
        />
        <v-btn
          block
          color="white"
          x-large
          rounded
          elevation="0"
          class="rounded-xl"
          height="60"
        >
          <v-icon color="#455a64">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WelcomeComponent from "../components/Home/WelcomeComponent";
import AchievementComponent from "../components/Home/AchievementComponent";
import InlineCardComponent from "../components/Home/InlineCardComponent.vue";
import ProgressLinearComponent from "../components/Home/ProgressLinearComponent";
import AppBarComponent from "../components/AppBarComponent.vue";
import LineChart from "../components/LineChart";
import CardBoxComponent from "../components/Home/CardBoxComponent.vue";

export default {
  name: "Home",
  data: () => ({
    linearBar: [
      { title: "Views", value: 75, color: "#2962ff" },
      { title: "Followers", value: 50, color: "#ffc400" },
      { title: "Income", value: 25, color: "#ff3d00" },
    ],
    meetings: [
      {
        cardC: "white",
        title: "Emmy Anderson",
        subtitle: "8:00 - 10:00",
        fIcon: "mdi-view-dashboard",
        fBackg: "white",
        fColor: "#455a64",
        sIcon: null,
        sColor: null,
        url: "https://picsum.photos/510/300?random",
      },
      {
        cardC: "white",
        title: "Emmy Anderson",
        subtitle: "8:00 - 10:00",
        fIcon: "mdi-view-dashboard",
        fBackg: "white",
        fColor: "#455a64",
        sIcon: null,
        sColor: null,
        url: "https://picsum.photos/200/300",
      },
      {
        cardC: "white",
        title: "Emmy Anderson",
        subtitle: "8:00 - 10:00",
        fIcon: "mdi-view-dashboard",
        fBackg: "white",
        fColor: "#455a64",
        sIcon: null,
        sColor: null,
        url: "https://picsum.photos/seed/picsum/200/300",
      },
    ],
    data: [
      {
        cardC: "white",
        title: "26,654",
        subtitle: "Likes",
        fIcon: "mdi-thumb-up",
        fBackg: "#2962ff",
        fColor: "white",
        sIcon: "mdi-menu-down",
        sColor: "primary",
      },
      {
        cardC: "white",
        title: "6,754",
        subtitle: "Love",
        fIcon: "mdi-heart",
        fBackg: "#ff3d00",
        fColor: "white",
        sIcon: "mdi-menu-down",
        sColor: "warning",
      },
      {
        cardC: "white",
        title: "52,654",
        subtitle: "Smiles",
        fIcon: "mdi-emoticon",
        fBackg: "#ffc400",
        fColor: "white",
        sIcon: "mdi-menu-down",
        sColor: "danger",
      },
    ],
    notifications: [
      {
        id: "1",
        title: "You have 5 unread messages",
        subtitle: "9 months ago",
        url: "https://cdn.vuetifyjs.com/images/john.jpg",
      },
      {
        id: "2",
        title: "You have 1 unread messages",
        subtitle: "3 months ago",
        url: "https://cdn.vuetifyjs.com/images/john.jpg",
      },
    ],
    chartData: {
      labels: ["Jan", "Feb", "Mar", "Apr"],
      datasets: [
        {
          label: "Feb",
          borderColor: "#2962FF",
          pointBackgroundColor: "white",
          borderWidth: 6,
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "rgba(0, 0, 0, 0)",
          pointHoverBackgroundColor: "#2962FF",
          pointHoverBorderColor: "white",
          backgroundColor: "#BED0FF",
          data: [2.5, 1.4, 6, 4],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: 10,
      },
      elements: {
        point: {
          radius: 10,
          hoverRadius: 10,
          hoverBorderWidth: 2,
        },
      },
      hover: {
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 12,
              fontColor: "#455a64",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // beginAtZero: true,
              fontSize: 12,
              fontColor: "#455a64",
              padding: 10,
              display: false,
            },
          },
        ],
      },
    },
    childObject: {
      cardC: "#eceff1",
      title: "View Dashboard",
      subtitle: null,
      fIcon: "mdi-view-dashboard",
      fBackg: "white",
      fColor: "#455a64",
    },
  }),
  components: {
    WelcomeComponent,
    AchievementComponent,
    InlineCardComponent,
    ProgressLinearComponent,
    AppBarComponent,
    LineChart,
    CardBoxComponent,
  },
};
</script>
