<template >
  <div>
    <v-card elevation="0" class="rounded-xl">
      <div class="d-flex flex-column align-center pa-6">
        <div>
          <span class="card-title mb-2 text-center">{{ title }}</span>
          <span class="card-title subtitle text-center">{{ subtitle }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
<style scope>
.card-title {
  margin: 0px;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.167;
  letter-spacing: 0px;
  display: block;
  color: rgb(69, 90, 100);

}
.subtitle {
  font-weight: 400;
  font-size: 1.0rem;
  line-height: 1.43;
  color: rgb(96, 125, 139);
}
</style>