<template lang="">
  <div
    class="icon-bground mr-4"
    :style="{ 'background-color': bgColor }"
    >
    <v-icon :color="color">{{ icon }}</v-icon>
    </div>
</template>
<script>
export default {
  props: {
    color: String,
    icon: String,
    bgColor: String,
  },
};
</script>
<style scoped>
.icon-bground {
  border-radius: 50%;
  padding: 13px;
  width: 50px;
  height: 50px;
}
</style>