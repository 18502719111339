<template >
  <div>
    <v-card color="#2962ff" elevation="1" class="rounded-xl">
      <div class="d-flex flex-column align-center pa-6">
        <div class="icon-bground mb-6">
          <v-icon color="#2962ff">mdi-star</v-icon>
        </div>

        <div class="title-card white--text">Congratulations {{ userName }}</div>
        <div class="subtitle-card white--text">
          You have completed 75% of your profile. Your current progres is great.
        </div>
        <v-btn class="rounded-lg" style="font-size:12px" x-large color="white" elevation="2">View profile</v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    userName: "Marina",
  }),
};
</script>
<style scoped>
.icon-bground {
  border-radius: 50%;
  background-color: #ffffff;
  padding: 8px;
  width: 40px;
  height: 40px;
}

.container {
  display: flex;
  justify-content: center;
  /* margin: 10px 0px; */
}
.title-card {
  margin: 0px 0px 0.35em;
  font-family: Nunito, sans-serif;
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
}
.subtitle-card {
  margin: 0px 0px 24px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0px;
}
</style>