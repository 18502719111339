<template lang="">
    <div>
        
               <v-menu
              ref="menu"
              v-model="dropdownOpen"
              :close-on-content-click="false"
               transition="scale-transition"
              :return-value.sync="model"
           
               origin="center center"
              nudge-right="40"
              min-width="0px"
              >
              <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="displayDate"
                      :label="label"
                      :outlined="outlined"
                      :solo="solo"
                      :prepend-icon="showIcon?'event':''"
                       :rules="required?[rules.required]:[]"
                      readonly
                      v-on="on"
                  ></v-text-field>
              </template>

        <v-card flat>
            <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab>
            {{ currentDate }}
          </v-tab>
          <v-tab>
            {{ timeModel }}
          </v-tab>
        </v-tabs>
        <v-card-text >
         
         <v-tabs-items v-model="tab">
         <v-tab-item>

                    <v-date-picker 
                      v-model="dateModel"
                      color="primary">
                    </v-date-picker>
        </v-tab-item>
        <v-tab-item>
                 <v-time-picker 
                    v-if="dropdownOpen" 
                    v-model="timeModel" 
                    color="primary"
                    format="ampm"
                 ></v-time-picker>
      </v-tab-item>
    </v-tabs-items>
         
          </v-card-text > 
     <v-card-actions >
      <v-btn small @click="dropdownOpen = false" color="primary" text>Cancel</v-btn>
      <v-btn small @click="confirm()" color="primary" text>Save</v-btn>
     </v-card-actions > 
    </v-card>



<!-- 
             <div class="v-date-time-widget-container">
                  <v-layout row wrap>
                      <v-flex xs12 sm6>
                          <v-date-picker 
                              v-model="dateModel"
                              width="240"
                              color="primary"></v-date-picker>
                      
                      </v-flex>
                      <v-flex xs12 sm6>
                          <v-time-picker 
                              v-if="dropdownOpen" 
                              v-model="timeModel" 
                              color="primary"
                              width="240"
                              format="ampm"
                              ></v-time-picker>
                          <h3 class="text-xs-center">{{ currentSelection }}</h3>
                      </v-flex>
                  </v-layout>
                   <v-btn small @click="dropdownOpen = false" color="primary" text>Cancel</v-btn>
                          <v-btn small @click="confirm()" color="primary" text>Save</v-btn>
              </div> -->
          </v-menu>

      </div>
</template>
<script>
export default {
  props: {
    outlined: Boolean,
    solo: Boolean,
    label: String,
    showIcon: Boolean,
    required:Boolean,
  },
  data: () => ({
    tab: null,
        rules: {
      required: (value) => !!value || "Field is required.",
    },
    dropdownOpen: false,
    displayDate: "",
    dateModel: "",
    timeModel: "",
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(model) {},
    },
    currentDate() {
      return this.formatDate(this.dateModel);
    },
    currentSelection() {
      let selectedTime = this.timeModel;
      return this.formatDate(this.dateModel) + " " + selectedTime;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      let monthName = this.monthNames[parseInt(month)];
      return `${monthName} ${day}, ${year}`;
    },

    // Confirm the datetime selection and close the popover
    confirm() {
      this.onUpdateDate();
      this.dropdownOpen = false;
    },

    // Format the date and trigger the input event
    onUpdateDate() {
      if (!this.dateModel || !this.timeModel) return false;

      let selectedTime = this.timeModel;
      this.displayDate = this.formatDate(this.dateModel) + " " + selectedTime;
      this.$emit("input", this.dateModel + " " + selectedTime);
    },
  },
  mounted() {
    // Set the current date and time as default value
    var d = new Date();
    var currentHour = d.getHours() % 12; // AM,PM format
    var minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    var currentTime = currentHour + ":" + minutes;
    this.timeModel = currentTime;
    this.dateModel = d.toISOString().substr(0, 10);
  },
};
</script>
<style scoped>
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}

.v-card {
  box-shadow: none;
}

.btn-am {
  float: left;
}

.btn-pm {
  float: right;
}

.v-date-picker-table {
  height: auto;
}
</style>