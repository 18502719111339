<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selected.length > 0">
          <v-btn icon left small @click="selected = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selected.length }} Selected
          </span>
        </v-chip>
        <span class="bold_text-1" v-else>Oposiciones</span>
      </template>
      <!-- <template v-slot:action>
        <v-btn color="white" fab v-if="selected.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <v-btn color="primary" fab v-else @click="addItems">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template> -->
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <v-form ref="form" @submit.prevent="submit">
      <v-card flat color="blue-grey lighten-5" class="pt-6">
        <v-card-text>
          <v-card elevation="0" rounded class="rounded-xl">
            <div class="pa-6">
              <div class="bold_text-2">Actualizar oposiciones</div>
              <div>
                
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0 text-center">
                        <v-select
                          :items="empresas"
                          label="Empresa"
                          item-value="empresaId"
                          item-text="empresaNombre"
                          outlined
                          v-model="empresaId"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" class="pb-0 text-center">
                        <v-file-input
                          v-model="selectedFile"
                          outlined
                          small-chips
                          truncate-length="14"
                          :rules="[rules.required]"
                          label="Archivo"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                
              </div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text type="submit">
                <span class="text-none">Actualizar</span>
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-card-text>
      </v-card>
      </v-form>
    </div>
    <!-- <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="text-h5">Cargar oposiciones</span>
          </v-card-title>
          <v-card-text>
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog> -->
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import router from "../router/index";
import { mapState } from "vuex";

export default {
  name: "Oposiciones",
  data: () => ({
    empresaId:null,
    formDialog: false,
    dialogTitle: "",
    selectedFile: null,
    isSelecting: false,
    src: "",
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
    selected: [],
  }),
  methods: {
    addItems() {
      let vm = this;
      vm.selectedFile = null;
      vm.formDialog = true;
    },
    editItem(id) {
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar lugar";
      vm.getData();

      let e = vm.lugares.find((x) => x.lugarId === id);
      vm.formData = {
        lugarId: e.lugarId,
        lugarNombre: e.lugarNombre,
      };
      vm.formDialog = true;
    },
    removeItem() {},
    submit() {
      let vm = this;

      console.log(vm.empresaId)
      var data = new FormData();
      data.append("empresaId", vm.empresaId)
      data.append("file", vm.selectedFile);

      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Importar", data).then((d) => {
          if (d.success) {
            // vm.getAllList();
            vm.formDialog = false;
          }
        });
      }
      vm.formDialog = false;
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.src = URL.createObjectURL(this.selectedFile);
    },
    getAllList() {
      this.$store.dispatch("GET_Lugares");
    },

    getData() {
      this.$store.dispatch("GET_Empresas_Select");
    },
  },
  mounted() {
    this.getData();
    this.getAllList();
  },
  computed: {
    ...mapState(["empresas"]),
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.nombreBtn;
    },
    srcUrl() {
      return this.src
        ? this.src
        : require("../assets/Imagen no disponible.png");
    },
    selectAll: {
      get: function () {
        return this.lugares
          ? this.selected.length == this.lugares.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.lugares.forEach(function (emp) {
            selected.push(emp.lugarId);
          });
        }

        this.selected = selected;
      },
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>