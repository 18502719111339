<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selected.length > 0">
          <v-btn icon left small @click="selected = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selected.length }} Selected
          </span>
        </v-chip>
        <span class="bold_text-1" v-else> {{empresaNombre}} - Movimientos de cuenta</span>
      </template>
      <!-- <template v-slot:action>
        <v-btn color="white" fab v-if="selected.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <v-btn color="primary" fab v-else @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template> -->
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="text-left">
              <span class="normal_text-1">Fecha</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Tipo de movimiento</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Descripción</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Monto</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="balance.length == 0">
              <td colspan="4" class="text-center">
                  <span class="normal_text darken-2--text">** No hay movimientos en esta cuenta **</span>
              </td>
          </tr>
          <tr v-for="(item, index) in balance" :key="index">
            
            <td>
              <span>
                {{ item.fechaFormatted }}
              </span>
            </td>
             <td>
              <span class="">{{ item.tipo }}</span>
              
            </td>
             <td>
              <span >{{item.descripcion }}</span>
              
            </td>
             <td>
              
              <span >{{ item.monto | formatNumber }}</span>
            </td>
           <td class="text-center">
                        <v-btn icon @click="getFiles(item.movimientoId)">
                          <v-icon>mdi-file</v-icon>
                        </v-btn>
                      </td>
          </tr>
        </tbody>
      </table>
    </div>
     <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="text-h5">Movimientos</span>
          </v-card-title>
          <v-card-text>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          #
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Archivo
                        </span>
                      </th>
                      <th class="text-center">
                        <span class="normal_text-1 darken-2--text">
                         
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in archivos" :key="index">
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.archivoId
                        }}</span>
                      </td>
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.nombre
                        }}</span>
                      </td>
                      <td class="text-center">
                        <v-btn icon target="_blank" v-bind:href="$urlBase + 'api/Archivos/Descargar/' + item.archivoId">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import { currencyFormatted } from "../formatos";
import router from "../router/index";
import { mapState } from "vuex";


export default {
  name: "Movimientos",
  data: () => ({
    formDialog: false,
    dialogTitle: "",
    empresaNombre:"",
    selectedFile: null,
    archivos:[],
    isSelecting: false,
    balance:[],
    src: "",
    formData: {
      lugarId: 0,
      lugarNombre: "",
    },
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    selected: [],
  }),
  methods: {
    addItem() {
      let vm = this;

      vm.dialogTitle = "Agregar lugar";

      vm.selectedFile = null;
      vm.src = "";

      vm.formData = {
        lugarId: 0,
        lugarNombre: "",
      };
      vm.formDialog = true;
    },
    editItem(id) {
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar lugar";
      vm.getData();

      let e = vm.lugares.find((x) => x.lugarId === id);
      vm.formData = {
        lugarId: e.lugarId,
        lugarNombre: e.lugarNombre,
      };
      vm.formDialog = true;
    },
    removeItem() {},
    getFiles(id){
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar empresa";

      vm.$store.dispatch("Get_files", id).then((d) => {
        
        vm.archivos = d;
        vm.formDialog = true;
      });

     
    },
    submit() {
      let vm = this;

      var data = new FormData();
      var json = JSON.stringify(vm.formData);
      data.append("json", json);
      data.append("Image", vm.selectedFile);
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Lugar", data).then((d) => {
          if (d.success) {
            vm.getAllList();
            vm.formDialog = false;
          }
        });
        vm.formDialog = false;
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.src = URL.createObjectURL(this.selectedFile);
    },
    getAllList() {
      this.$store.dispatch("GET_Lugares");
    },

    getData() {
      this.$store.dispatch("GET_ROLES");
      this.$store.dispatch("GET_Planes");
      this.$store.dispatch("GET_Comprobantes");
    },
  },
  mounted() {
    let id = this.$router.currentRoute.query.id;
    let vm = this;
    this.$store.dispatch("GET_Balances_By_Id", id).then((d) => {
        
        vm.balance = d.balances;
        vm.empresaNombre = d.nombre;
    });

    this.getData();
    this.getAllList();
  },
  computed: {
    
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.nombreBtn;
    },
    srcUrl() {
      return this.src
        ? this.src
        : require("../assets/Imagen no disponible.png");
    },
    selectAll: {
      get: function () {
        return this.lugares
          ? this.selected.length == this.lugares.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.lugares.forEach(function (emp) {
            selected.push(emp.lugarId);
          });
        }

        this.selected = selected;
      },
    },
  },
  filters: {
    formatNumber: function (number) {
      return currencyFormatted(number);
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>