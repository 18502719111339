<template >
  <div>
    <div class="mt-2 mb-2 text-center">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :color="color"
        :value="value"
        class="progress-circular"
        :style="{ width: dimension + 'px', height: dimension + 'px' }"
      >
       <span :style="textStyle" >{{ value }}</span> 
      </v-progress-circular>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    value: Number,
    color: String,
    color: String,
    dimension: Number,
    textStyle:Object,
  },
};
</script>
<style scoped>
.progress-circular {
  stroke-linecap: round;
}
.v-progress-circular {
  margin: 1rem;
}
</style>