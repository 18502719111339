<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selected.length > 0">
          <v-btn icon left small @click="selected = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selected.length }} Selected
          </span>
        </v-chip>
        <span class="bold_text-1" v-else>Reportes</span>
      </template>
      <template v-slot:action>
        <v-btn color="white" fab v-if="selected.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <!-- <v-btn color="primary" fab v-else @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </template>
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%">
              <v-checkbox
                class="center"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
            </th>
            <th class="text-left">
              <span class="normal_text-1"># Reporte</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Fecha</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Tipo de reporte</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Usuario que reporto</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Esta atendido</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="reportes.length == 0">
            <td colspan="7" class="text-center">
              ** No se han realizado reportes **
            </td>
          </tr>
          <tr v-for="(item, index) in reportes" :key="index">
            <td>
              <v-checkbox
                color="primary"
                v-model="selected"
                :value="item.reporteId"
              ></v-checkbox>
            </td>
            <td>
              <div class="d-flex">
                <div>
                  <span class="normal_text darken-2--text">{{
                    item.reporteId
                  }}</span>
                </div>
              </div>
            </td>
            <td>
              {{ item.fechaCreacionFormatted }}
            </td>
            <td>
              {{ item.tipoReporteNombre }}
            </td>
            <td>{{ item.correoElectronico }} ({{ item.usuario }})</td>
            <td>
              <v-chip
                class="ma-2 white--text"
                color="blue accent-4"
                v-if="item.estaAtendidoB"
              >
                Sí
              </v-chip>
              <v-chip class="ma-2" color="blue-grey lighten-5" v-else>
                No
              </v-chip>
            </td>
            <td>
              <v-menu bottom left offset-x rounded="xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="reportDetail(item.reporteId)">
                      <v-list-item-icon>
                        <v-icon> mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Detalles</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="marcarAtendido(item.reporteId)">
                      <v-list-item-icon>
                        <v-icon> mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Marcar como atendido</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>-->
                  </v-list-item-group> 
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="text-h5">Reporte #{{ formData.reporteId }}</span>
          </v-card-title>
          <v-card-text>
              <v-card 
              elevation="0"
              class="mx-auto" 
              >
                <v-card-text v-if="formData.tipoReporteId == 1">
                  <div>Fecha del reporte: {{formData.fechaCreacionFormatted}}</div>
                  <p class="text-h6 text--primary">
                    Comentado a la oposición Num: {{formData.oposNum}}</p>
                  <div class="text--primary">
                     {{formData.comentario}}
                  </div>
                  <p>{{formData.usuarioC}}</p>
                  <p>{{formData.comentarioFechaFormatted}}</p>
                  
                </v-card-text>
                <v-card-text v-else>
                  <div>Fecha del reporte: {{formData.fechaCreacionFormatted}}</div>
                  <p class="text-h6 text--primary">
                    Oposición Num: {{formData.num}}</p>
                  <!-- <div class="text--primary">
                     {{formData.comentario}}
                  </div> -->
                  <p>{{formData.usuario}}</p>
                  <p>{{formData.oposicionFechaFormatted}}</p>
                  
                </v-card-text>
              </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cerrar
            </v-btn>
            <v-btn color="red darken-1" v-if="formData.tipoReporteId == 1" text type="submit" @click="deleteComent(formData.id, formData.reporteId)"> Eliminar comentario </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import router from "../router/index";
import { mapState } from "vuex";

export default {
  name: "Reportes",
  data: () => ({
    formDialog: false,
    dialogTitle: "",
    selectedFile: null,
    isSelecting: false,
    src: "",
    formData: {},
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    selected: [],
  }),
  methods: {
    deleteComent(id, reporteId){

        let vm = this;

       let data = {
          cId : id,
          rId : reporteId
       }
        this.$store.dispatch("Delete_Comentario", data).then(() => {
            vm.getAll();
        });
    },
    addItem() {
      let vm = this;

      vm.dialogTitle = "Agregar empresa";

      vm.selectedFile = null;
      vm.src = "";

      vm.formData = {
        empresaId: 0,
        planId: 0,
        estadoId: 0,
        esEmpresa: false,
        nombre: "",
        rnc: "",
        direccion: "",
        telefono: null,
        facturarConValorFiscal: false,
        tipoComprobanteId: 0,
      };
      vm.formDialog = true;
    },
    marcarAtendido(id) {
      let vm = this;
      this.$store.dispatch("Reporte_Atendido", id).then(() =>{
        vm.getAll();
      });
      
    },
    reportDetail(id) {
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar empresa";

      vm.$store.dispatch("GET_Reporte_By_Id", id).then((d) => {
        vm.formData = d;
       
      });

      vm.formDialog = true;
    },
    removeItem() {},
    submit() {
      let vm = this;

      var data = new FormData();
      var json = JSON.stringify(vm.formData);
      data.append("json", json);
      data.append("Image", vm.selectedFile);
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Empresa", data).then((d) => {
          if (d.success) {
            vm.getAllEmpresas();
            vm.formDialog = false;
          }
        });
        vm.formDialog = false;
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.src = URL.createObjectURL(this.selectedFile);
    },
    getAll() {
      this.$store.dispatch("GET_Reportes");
    },

    getData() {
      this.$store.dispatch("GET_ROLES");
      this.$store.dispatch("GET_Planes");
      this.$store.dispatch("GET_Comprobantes");
    },
  },
  mounted() {
    this.getData();
    this.getAll();
  },
  computed: {
    ...mapState(["reportes", "planes", "comprobantes"]),
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.nombreBtn;
    },
    srcUrl() {
      return this.src
        ? this.src
        : require("../assets/Imagen no disponible.png");
    },
    selectAll: {
      get: function () {
        return this.reportes
          ? this.selected.length == this.reportes.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.reportes.forEach(function (emp) {
            selected.push(emp.reporteId);
          });
        }

        this.selected = selected;
      },
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>