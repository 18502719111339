<template >
  <div class="pa-12">
    <div
      class="d-flex align-center justify-center flex-column"
      style="width: 100%; height: 100%"
    >
      <v-img contain width="400" height="400" src="../assets/help.png"></v-img>
    </div>
    <div></div>
    <v-row>
      <v-col
        lg="3"
        md="3"
        sm="3"
        cols="12"
        v-for="(item, index) in elements"
        :key="index"
      >
        <v-card class="rounded-xl" rounded elevation="0" v-ripple>
          <div class="pa-4">
            <IconWithBackground
              :color="item.color"
              :icon="item.icon"
              :bgColor="item.bgColor"
            />
            <div class="pa-4">
              <span class="normal_text">{{ item.title }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import IconWithBackground from "../components/IconWithBackground.vue";
export default {
  name: "Help",
  data: () => ({
    elements: [
      {
        icon: "mdi-school",
        color: "#455A64",
        bgColor: "#ECEFF1",
        title: "Guide",
      },
      {
        icon: "mdi-help-circle",
        color: "#455A64",
        bgColor: "#ECEFF1",
        title: "FAQ",
      },
      {
        icon: "mdi-lifebuoy",
        color: "#455A64",
        bgColor: "#ECEFF1",
        title: "Support",
      },
      {
        icon: "mdi-email",
        color: "#455A64",
        bgColor: "#ECEFF1",
        title: "Contact",
      },
    ],
  }),
  components: {
    IconWithBackground,
  },
};
</script>
<style lang="">
</style>