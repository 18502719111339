function currencyFormatted(amount) {
    var i = parseFloat(amount);
    if (isNaN(i)) { i = 0.00; }
    var minus = '';
    if (i < 0) { minus = '-'; }
    i = Math.abs(i);
    i = parseInt((i + .005) * 100);
    i = i / 100;
    let s = new String(i);
    if (s.indexOf('.') < 0) { s += '.00'; }
    if (s.indexOf('.') == (s.length - 2)) { s += '0'; }
    s = minus + s;
    return addCommas(s);
  }
  
  function addCommas(number) {
      number += '';
      let x = number.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1))
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
      return x1 + x2;
  }

  function removeCommas(number) {
    if (typeof number == "undefined") return;
    var num = number.toString().replace(/\,/g, "");
    return parseFloat(num);
}

  export { currencyFormatted, addCommas, removeCommas};