<template >
  <v-container>
    <app-bar-component>
      <template v-slot:title>
        <span class="bold_text-1"> Balance</span>
      </template>
    </app-bar-component>

    <v-row>
      <v-col
        lg="3"
        md="3"
        sm="6"
        cols="6"
        v-for="(item, index) in overViews"
        :key="index"
      >
        <OverViewComponent :title="item.title" :subtitle="item.subtitle" />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :maxHeight="'232px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">Cuentas</div>
          </template>
          <template v-slot:body>
            <div class="pa-6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Empresas
                        </span>
                      </th>
                      <!-- <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Progress
                        </span>
                      </th> -->
                      <th class="text-center">
                        <span class="normal_text-1 darken-2--text">
                          Balance
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in balances" :key="index">
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.nombre
                        }}</span>
                      </td>
                      <!-- <td>
                        <div class="d-flex">
                          <v-progress-linear
                            v-model="item.porcentage"
                            :color="item.color"
                            height="12"
                            rounded
                          ></v-progress-linear>
                          <div
                            class="bold_text-2 ml-2"
                            :class="item.color + '--text'"
                          >
                            {{ item.porcentage }}%
                          </div>
                        </div>
                      </td> -->
                      <td class="text-center">
                        <div
                          
                          class="text-2 text-red darken-2--text"
                          v-if="item.balance < 0"
                        >
                         <span > {{ item.balance | formatNumber }}</span>
                        </div>
                        <div class="text-2 text-red darken-2--text" v-else>
                          {{ item.balance | formatNumber }}
                        </div>
                      </td>
                      <td class="text-center">
                        <v-btn icon @click="getBalanceDetails(item.empresaId)">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </card-box-component>
      </v-col>
      <!-- <v-col lg="8" md="8" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :id="1"
          :maxHeight="'292px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">Activity</div>
          </template>
          <template v-slot:body>
            <LineChart :chartdata="chartData" :options="chartOptions" />
          </template>
        </card-box-component>
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :maxHeight="'292px'"
          :id="2"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">Budget</div>
          </template>
          <template v-slot:body>
            <RadarChart :chartdata="radarData" :options="radarOptions" />
          </template>
        </card-box-component>
      </v-col> -->
    </v-row>
    <!-- <v-row>
      <v-col lg="4" md="4" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :id="3"
          :maxHeight="'232px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">
              Sales History
            </div>
          </template>
          <template v-slot:body>
            <BarChart :chartdata="barData" :options="barOptions" />
          </template>
          <template v-slot:action>
            <div class="mt-4 d-flex">
              <div>
                <div class="bold_text-1 blue-grey--text">567</div>
                <div class="text-1">$ today</div>
              </div>
              <div class="ml-auto">
                <v-icon color="blue-grey" large>mdi-finance</v-icon>
              </div>
            </div>
          </template>
        </card-box-component>
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <inline-card-component
          :element="item"
          v-for="(item, index) in inlineCards"
          :key="index"
        >
          <template v-slot:body>
            <ProgressLinearComponent
              :title="item.progressTitle"
              :value="item.progressValue"
              :color="item.progressColor"
              :noMargin="true"
            />
          </template>
        </inline-card-component>
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :maxHeight="'232px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">Progress</div>
          </template>
          <template v-slot:body>
            <CircularBarComponent
              :value="75"
              :color="'primary'"
              :dimension="dimension"
              :textStyle="{
                color: '#455A64',
                fontSize: '30px',
                fontWeight: '800',
              }"
            />
          </template>
        </card-box-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="4" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :maxHeight="'232px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">
              Recent Users
            </div>
          </template>
          <template v-slot:body>
            <div class="pt-5">
              <inline-card-component
                :element="item"
                v-for="(item, index) in recentUsers"
                :key="index"
                :noPadding="true"
              >
                <template v-slot:action>
                  <v-btn icon large>
                    <v-icon color="#455a64">mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
              </inline-card-component>
            </div>
          </template>
        </card-box-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="4" sm="12">
        <card-box-component
          :maxWidth="'auto'"
          :id="4"
          :maxHeight="'292px'"
          :clases="'justify-center flex-column'"
        >
          <template v-slot:header>
            <div class="bold_text-2 blue-grey--text text-left">
              Sales History
            </div>
          </template>
          <template v-slot:body>
            <PieChart :chartdata="pieData" :options="pieOptions" />
          </template>
        </card-box-component>
      </v-col>
    </v-row> -->

     <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="text-h5">Movimientos</span>
          </v-card-title>
          <v-card-text>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Fecha
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Tipo de movimiento
                        </span>
                      </th>
                      <th class="text-center">
                        <span class="normal_text-1 darken-2--text">
                          Descripción
                        </span>
                      </th>
                      <th class="text-right">
                        <span class="normal_text-1 darken-2--text">
                          Monto
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in movimientos" :key="index">
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.fechaFormatted
                        }}</span>
                      </td>
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.tipo
                        }}</span>
                      </td>
                      <td>
                        <span class="text-1 blue-grey--text text-center">{{
                          item.descripcion
                        }}</span>
                      </td>
                      <td class="text-right">
                        <div
                          
                          class="text-2 text-red darken-2--text"
                          v-if="item.monto < 0"
                        >
                         <span > {{ item.monto | formatNumber }}</span>
                        </div>
                        <div class="text-2 text-red darken-2--text" v-else>
                          {{ item.monto | formatNumber }}
                        </div>
                      </td>
                      <td class="text-center">
                        <v-btn icon @click="getFiles(item.movimientoId)">
                          <v-icon>mdi-file</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
     <v-dialog v-model="formDialogArchivos" persistent max-width="600px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="text-h5">Movimientos</span>
          </v-card-title>
          <v-card-text>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          #
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="normal_text-1 darken-2--text">
                          Archivo
                        </span>
                      </th>
                      <th class="text-center">
                        <span class="normal_text-1 darken-2--text">
                         
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in archivos" :key="index">
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.archivoId
                        }}</span>
                      </td>
                      <td>
                        <span class="text-1 blue-grey--text">{{
                          item.nombre
                        }}</span>
                      </td>
                      <td class="text-center">
                        <v-btn icon target="_blank" v-bind:href="$urlBase+'api/Archivos/Descargar/' + item.archivoId">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialogArchivos = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import OverViewComponent from "../components/Dashboard/OverViewComponent.vue";
import CardBoxComponent from "../components/Home/CardBoxComponent.vue";
import InlineCardComponent from "../components/Home/InlineCardComponent.vue";
import ProgressLinearComponent from "../components/Home/ProgressLinearComponent.vue";
import CircularBarComponent from "../components/Dashboard/CircularBarComponent.vue";
import LineChart from "../components/LineChart";
import RadarChart from "../components/RadarChart";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";
import { mapState } from "vuex";
import { currencyFormatted } from "../formatos";
import router from "../router/index";

export default {
  name: "Dashboard",
  data: () => ({
    dimension: 0,
    formDialog: false,
    movimientos: [],
    archivos: [],
    formDialogArchivos: false,
    // overViews: [
    //   { title: "10", subtitle: "Usuarios totales" },
    //   { title: "8", subtitle: "Usuarios activos" },
    //   { title: "2", subtitle: "Usuarios inactivos" },
    //   // { title: "657", subtitle: "Users" },
    // ],
    chartData: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      datasets: [
        {
          label: "Feb",
          borderColor: "#2962FF",
          pointBackgroundColor: "white",
          borderWidth: 6,
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "#2962FF",
          pointHoverBackgroundColor: "#2962FF",
          pointHoverBorderColor: "white",
          backgroundColor: "white",
          data: [2400, 1398, 9800, 3908, 4800, 3800, 4300],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: 10,
      },
      elements: {
        point: {
          radius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
        },
      },
      hover: {
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 12,
              fontColor: "#455a64",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              // beginAtZero: true,
              fontSize: 12,
              fontColor: "#455a64",
              //  max: 11000,

              padding: 10,
              display: false,
            },
          },
        ],
      },
    },
    radarData: {
      labels: ["Marketing", "Research", "Sales", "Ops", "HR", "Dev"],
      datasets: [
        {
          label: "Feb",
          fill: true,
          borderColor: "#2962FF",
          pointBackgroundColor: "white",
          borderWidth: 2,
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "#2962FF",
          pointHoverBackgroundColor: "#2962FF",
          pointHoverBorderColor: "white",
          data: [110, 98, 86, 99, 85, 65],
          backgroundColor: "#2962FF",
        },
      ],
    },
    radarOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        color: "red",
      },

      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 3,
          hoverRadius: 10,
          hoverBorderWidth: 1,
        },
      },
      hover: {
        intersect: false,
      },
      scale: {
        // Hides the scale
        display: true,
        ticks: {
          min: 0,
          max: 110,
          padding: 20,
          display: false,
        },
        gridLines: {
          display: false,
        },
        pointLabels: {
          fontSize: 12,
          fontColor: "#455a64",
        },
      },
    },
    barData: {
      labels: ["January", "February", "March", "April", "May", "June"],
      datasets: [
        {
          borderColor: "#2962FF",
          data: [8, 6, 3, 5, 3, 4],
          backgroundColor: "#2962FF",

          borderWidth: 2,
          borderRadius: 15,
        },
      ],
    },
    barOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: 20,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 12,
              fontColor: "#455a64",
              max: 10,

              padding: 10,
              display: false,
            },
          },
        ],
      },
    },
    pieData: {
      labels: ["Books", "Movies & TV", "Software"],
      backgroundColor: [
        "rgb(41, 98, 255)",
        "rgb(255, 196, 0)",
        "rgb(255, 61, 0)",
      ],
      datasets: [
        {
          label: "My First Dataset",
          data: [400, 300, 300],
          backgroundColor: [
            "rgb(41, 98, 255)",
            "rgb(255, 196, 0)",
            "rgb(255, 61, 0)",
          ],
          hoverOffset: 8,
        },
      ],
    },
    pieOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
      layout: {
        padding: 20,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 12,
              fontColor: "#455a64",
              max: 10,

              padding: 10,
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 12,
              fontColor: "#455a64",
              max: 10,

              padding: 10,
              display: false,
            },
          },
        ],
      },
    },

    inlineCards: [
      {
        cardC: "white",
        fIcon: "mdi-account-multiple",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
        progressTitle: "Visits",
        progressValue: 75,
        progressColor: "#2962ff",
      },
      {
        cardC: "white",
        fIcon: "mdi-basket",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
        progressTitle: "Orders",
        progressValue: 50,
        progressColor: "#2962ff",
      },
      {
        cardC: "white",
        fIcon: "mdi-currency-usd",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
        progressTitle: "Sales",
        progressValue: 25,
        progressColor: "#2962ff",
      },
    ],
    recentUsers: [
      {
        cardC: "white",
        title: "Arriaga Rhys",
        subtitle: "Admin",
        fIcon: "mdi-account",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
      },
      {
        cardC: "white",
        title: "Core Laura",
        subtitle: "Member",
        fIcon: "mdi-account",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
      },
      {
        cardC: "white",
        title: "Jagger Joshua",
        subtitle: "Member",
        fIcon: "mdi-account",
        fBackg: "#ECEFF1",
        fColor: "#455a64",
      },
    ],
    teamProgress: [
      {
        id: 1,
        title: "Marketing Team",
        color: "primary",
        porcentage: 75,
        value: "122",
      },
      {
        id: 2,
        title: "Operations Team",
        color: "amber",
        porcentage: 50,
        value: "82",
      },
      {
        id: 3,
        title: "Sales Team",
        color: "red",
        porcentage: 25,
        value: "39",
      },
      {
        id: 4,
        title: "Research Team",
        color: "blue-grey",
        porcentage: 10,
        value: "9",
      },
    ],
  }),

  methods: {
    resize() {
      let vm = this;
      let xl = 250;
      let lg = 250;
      let md = 200;
      let sm = 200;
      let xs = 100;
      let res = 0;
      let item = vm.$vuetify.breakpoint;
      switch (item.name) {
        case "lg": {
          res = lg;
          break;
        }
        case "md": {
          res = md;
          break;
        }
        case "sm": {
          res = sm;
          break;
        }
        case "xs": {
          res = xs;
          break;
        }
        default:
          {
            res = xl;
          }
          break;
      }
      this.dimension = res;
    },
    getData() {
      this.$store.dispatch("GET_Balances");
      this.$store.dispatch("GET_Usuarios_By_Estados");
    },
    getBalanceDetails(id){
      router.push({ path: 'Movimientos', query: { id: id }})
     
    },
    getFiles(id){
      let vm = this;
      vm.selectedFile = null;
      vm.src = "";
      vm.dialogTitle = "Editar empresa";

      vm.$store.dispatch("Get_files", id).then((d) => {
        
        vm.archivos = d;
        vm.formDialogArchivos = true;
      });

     
    },

    descargar(id){
      this.$store.dispatch("descargar",id);
    }
  },
  filters: {
    formatNumber: function (number) {
      return currencyFormatted(number);
    },
  },
  mounted() {
    
    let id = this.$router.currentRoute.query.id;

    
    this.resize();
    this.getData();
  },
  computed: {
    ...mapState(["balances","overViews"]),
  },
  components: {
    AppBarComponent,
    OverViewComponent,
    CardBoxComponent,
    LineChart,
    RadarChart,
    BarChart,
    PieChart,
    InlineCardComponent,
    ProgressLinearComponent,
    CircularBarComponent,
  },
};
</script>
<style >
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: none !important;
}
</style>