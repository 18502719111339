<template >
  <div
    class="d-flex align-center justify-center flex-column"
    style="width: 100%; height: 100%"
  >
    <div>
      <v-img
        contain
        width="90%"
        height="90%"
        src="../assets/building.png"
      ></v-img>
    </div>
    <div class="bold_text-2">Under constructions!</div>
    <div class="normal_text-1">We are actively working on this page.</div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>