<template >
  <v-app-bar
    app
    color="#ECEFF1"
    height="104"
    rounded
    prominent
    elevation="0"
    :class="[$vuetify.breakpoint.xs ? 'pl-6 pr-6' : 'pl-12 pr-12']"
  >
    <v-app-bar-nav-icon
      class="align-self-center"
      @click="toogleDrawer"
      v-if="$vuetify.breakpoint.width < breackpoint"
    ></v-app-bar-nav-icon>
    <div class="d-flex align-self-center">
      <slot name="title"></slot>
    </div>

    <v-spacer></v-spacer>
    <div class="d-flex align-self-center">
      <slot name="action"></slot>

      <!-- <v-badge color="red" dot overlap>
        <v-btn icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge> -->
    </div>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBarComponent",
  data: () => ({
    drawer: false,
    breackpoint: 1280,
  }),
  methods: {
    toogleDrawer() {
      let vm = this;
      let res = (vm.drawer = !vm.drawer);
      this.$eventHub.$emit("drawer-toogle", res);
    },
  },
  created() {
    let vm = this;
    vm.$eventHub.$on("drawer-value", (data) => {
      vm.drawer = data;
    });
  },
  // props: {
  //   title: String,
  // },
};
</script>
<style lang="">
</style>