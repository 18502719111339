<template >
  <div>
    <v-card color="transparent" elevation="0" class="pa-6 mb-2" >
      <div class="mb-2 title-card bolded-title">Hi {{ userName }},</div>
      <div class="mb-4 title-card">Welcome back!</div>
      <div>
        <p class="title-card card-p">This page is designed to give some important information about the
          application. Let's make someting together!</p
        >
      </div>
      <div class="d-flex justify-center" >
            <v-img
          contain
          width="90%"
          height="90%"
          src="../../assets/welcome.png"
        ></v-img>
      </div>
    </v-card>
  </div>
</template>
<script>

export default {
  data: () => ({
    userName: "Marina",
  }),
};
</script>
<style scoped>
.title-card {
  font-size: 2rem;
  font-family: "Nunito", sans-serif;
  margin: 0px 0px 24px;
  line-height: 1.167;
  letter-spacing: 0px;
  font-weight: 500;
  color: rgb(69, 90, 100);
}
.card-p{
    font-weight: 400;
    font-size: 1rem ;
    line-height: 1.75;
    letter-spacing: 0px;
    color: rgb(96, 125, 139);
}
.bolded-title {
  font-weight: 800;
}
</style>
