<template>
  <div class="container-form">
    <div class="row-content">
      <div id="imagearea"></div>
      <div class="div-form">
       <v-card
       elevation="0"
       >
      <v-card-title>
        <span class=" is-title">Iniciar sesión</span>
      </v-card-title>
      <v-row>
        <v-card-text>
          <v-form
            @submit.prevent="handleSubmit"
            ref="form"
          >
            <v-container>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    label="Usuario"
                    required
                    v-model="usuarioNombre"
                    solo
                    clearable
                    :rules="[rules.required]"
                    append-icon="mdi-account"
                  ></v-text-field>
                    </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    label="Contraseña"
                    required
                    v-model="password"
                    solo
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.required]"
                    @click:append="show = !show"
                  ></v-text-field>
                    </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="2"
                type="submit"
                :disabled="invalid"
                :loading="loading"
              >
                Acceder
                </v-btn>
            </v-container>
            </v-form>
        </v-card-text>
      </v-row>
    </v-card>
      </div>
    </div>
  </div>
</template>
 <style>
body {
  background-color: aliceblue;
}
.is-title{
    font-family: "Nunito", sans-serif !important;
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
}
.container-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 600px;
  margin-top: 50px;
  margin-left: 10%;
  margin-right: 10%;
}

.row-content {
  min-height: 600px;
  display: flex;
}

.div-form {
  width: 520px;
  height: auto;
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

#imagearea {
  width: calc(100% - 520px);
  background-image: url(../assets/ok.png);
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  background-color: #2a695526;
}

/*@@media (min-width:576){

           body{
               background-color:red;
           }

           .container-form{
               min-height:auto;
           }

           .row-content{
               min-height:auto;
           }

         .div-form {
             width: 320px;
             height: auto;
             padding-top: 40px;
             padding-left: 15px;
             padding-right: 15px;
         }
       }*/
</style>
<script>
import { mapState } from "vuex";
export default {
  name: "Login",
  data: () => ({
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
    usuarioNombre: "",
    password: "",
    invalid: false,
    loading: false,
    show: false,
    snackbar: false,
    text: "",
    success: false,
  }),
  methods: {
    handleSubmit() {
      let vm = this;
      const data = {
        usuario: this.usuarioNombre,
        password: this.password,
        identificador: "1"
      };

      if (this.$refs.form.validate()) {
        this.$store.dispatch("login", data);
      }
    },
  },
};
</script>
