import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router';
import decode from 'jwt-decode'
import {checkAuthorized} from '../global'

Vue.use(Vuex)

const apiURL = "https://www.opos.com.do/OposApi/";

export default new Vuex.Store({
  state: {
    token: "",
    usuario: {},
    usuarios: [],
    usuarioModel: {},
    notification: {},
    empresas: [],
    roles: [],
    comprobantes: [],
    planes: [],
    empresa: {},
    lugares: [],
    reportes: [],
    reporte: {},
    balances: [],
    balance: [],
    archivos:[],
    overViews:[]
  },
  mutations: {
    SET_Usuarios: (state, usuarios) => { state.usuarios = usuarios },
    SET_Usuario: (state, usuario) => { state.usuarioModel = usuario },
    SET_Empresas: (state, empresas) => { state.empresas = empresas },
    SET_Roles: (state, roles) => { state.roles = roles },
    SET_Comprobantes: (state, comprobantes) => { state.comprobantes = comprobantes },
    SET_Planes: (state, planes) => { state.planes = planes },
    SET_Empresa: (state, empresa) => { state.empresa = empresa },
    SET_Lugares: (state, lugares) => { state.lugares = lugares },
    SET_Reportes: (state, reportes) => { state.reportes = reportes },
    SET_Reporte: (state, reporte) => { state.reporte = reporte },
    SET_Balances: (state, balances) => { state.balances = balances },
    PUSH_NOTIFICATION: (state, notification) => (state.notification = notification),
    SET_TOKEN: (state, token) => (state.token = token),
    LOAD_USER: (state, usuario) => (state.usuario = usuario),
    SET_Balances_Detalle: (state, balance) => (state.balance = balance),
    SET_archivos: (state, archivos) => (state.archivos = archivos),
    SET_over: (state, overViews) => (state.overViews = overViews)
  },
  actions: {
    GET_USUARIOS(state, id) {
      
      axios.get(apiURL + 'api/Account/Usuarios?id=' + id
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
      .then(response => {
        
        

        state.commit('SET_Usuarios', response.data)
        
      })
      .catch(function(err) { 
        console.log(err) 
        
        checkAuthorized(err,router);
        
      
      })
    },

    GET_CURRENT_USER(state, id) {
      axios.get(apiURL + 'api/Account/UserModel'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_Usuario', response.data)
        })
        .catch(err => { console.log(err) 
          checkAuthorized(err,router);
        })
    },

    Agregar_Usuario({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Account/Registro', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          }).catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Actualizar_Usuario({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Account/Actualizar', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Actualizar_Estado_Usuario({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Account/ActualizarEstado', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Change_Password({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Account/CambiarPassword', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Importar({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Oposiciones/Importar', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    GET_Lugares(state,) {
      axios.get(apiURL + 'api/Lugares'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_Lugares', response.data)
        })
        .catch(err => { console.log(err) 
        
          checkAuthorized(err,router);
        })
    },

    GET_Usuarios_By_Estados(state) {
      axios.get(apiURL + 'api/Movimientos/UsuariosPorEstados'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_over', response.data)
        })
        .catch(err => { console.log(err) 
          checkAuthorized(err,router);
        })
    },

    Agregar_Lugar({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Lugares/LugarForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    GET_Reportes(state) {
      axios.get(apiURL + 'api/Reportes'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        })
        .then(response => {
          state.commit('SET_Reportes', response.data)
        })
        .catch(err => { console.log(err) 
        
          checkAuthorized(err,router);
        })
    },

    GET_ROLES(state) {
      axios.get(apiURL + 'api/Account/Roles'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        })
        .then(response => {
          state.commit('SET_Roles', response.data)
        })
        .catch(err => { console.log(err) 
        
          checkAuthorized(err,router);
        })
    },

    GET_Comprobantes(state) {
      axios.get(apiURL + 'api/Empresas/Comprobantes'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        })
        .then(response => {
          state.commit('SET_Comprobantes', response.data)
        })
        .catch(err => { console.log(err) 
          checkAuthorized(err,router);
        
        })
    },

    GET_Planes(state) {
      axios.get(apiURL + 'api/Empresas/Planes'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        })
        .then(response => {
          state.commit('SET_Planes', response.data)
        })
        .catch(err => { console.log(err) 
          checkAuthorized(err,router);
        
        })
    },

    GET_Empresas_Select(state) {
      axios.get(apiURL + 'api/Empresas/EmpresasSelect'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_Empresas', response.data)
        })
        .catch(err => { console.log(err); checkAuthorized(err,router); })
    },

    GET_Empresas(state) {
      // console.log(state.state.token)
      axios.get(apiURL + 'api/Empresas'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_Empresas', response.data)
        })
        .catch(err => { console.log(err); checkAuthorized(err,router); })
    },

    Desactivar_Empresa({ commit, state }, data) {
     // console.log(state)
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Empresas/Desactivar', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Aprobar_Empresa({ commit, state }, data) {
      //console.log(state)
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Empresas/Aprobar', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    GET_Balances(state) {
      // console.log(state.state.token)
      axios.get(apiURL + 'api/Empresas/Balances'
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {

          // console.log(response)
          state.commit('SET_Balances', response.data)
          
        })
        .catch(err => { console.log(err); checkAuthorized(err,router); })
    },

    GET_Balances_By_Id(state, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'api/Empresas/BalanceById/' + id
          , {
            headers: {
              Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {
            state.commit('SET_Balances_Detalle', response.data)
            resolve(response.data)
          })
          .catch(err => { console.log(err); checkAuthorized(err,router); })
      })
    },

    Get_files(state, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'api/Empresas/ArchivosMovimientos/' + id
          , {
            headers: {
              Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {
            state.commit('SET_archivos', response.data)
            resolve(response.data)
          })
          .catch(err => { console.log(err); checkAuthorized(err,router); })
      })
    },

    GET_EmpresaById(state, id) {
      axios.get(apiURL + 'api/Empresas/EmpresaById?id=' + id
        , {
          headers: {
            Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
          }
        }
      )
        .then(response => {
          state.commit('SET_Empresa', response.data)
        })
        .catch(err => { console.log(err); checkAuthorized(err,router); })
    },

    Agregar_Empresa({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Empresas/EmpresaForm', data
          , {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          }).catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    descargar({ commit, state }, id) {
      // return new Promise((resolve, reject) => {
        axios.get(apiURL + 'api/Archivos/Descargar/' + id, 
           {
            headers: {
              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            // this.dispatch("addNotification", {
            //   success: response.data.success,
            //   message: response.data.message,
            //   show: true,
            // });
            // if (response.data.success) {
            //   resolve(response.data);
            // }
          })
          .catch(err =>{
            checkAuthorized(err,router);
            this.dispatch("addNotification", {
                success: false,
                message: err,
                show: true,
              });
          })
      // });
    },

    GET_Reporte_By_Id(state, id) {
      return new Promise((resolve, reject) => {
        axios.get(apiURL + 'api/Reportes/' + id
          ,
          {
            headers: {
              Authorization: 'Bearer ' + state.state.token //the token is a variable which holds the token
            },
            params: { id: id }
          }
        )
          .then(response => {
            state.commit('SET_Reporte', response.data)
            resolve(response.data)
          })
          .catch(err => { console.log(err); checkAuthorized(err,router); })
      })

    },

    Delete_Comentario({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Comentarios/EliminarAdmin?id=' + data.cId + '&reporteId=' + data.rId
          , {
            headers: {

              Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
            }
          }
        )
          .then(response => {

            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          }).catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },

    Reporte_Atendido({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios.post(apiURL + 'api/Reportes/ReporteAtendido/' + id
          , id, {
          headers: {
            Authorization: 'Bearer ' + state.token //the token is a variable which holds the token
          }
        }
        )
          .then(response => {
            this.dispatch("addNotification", {
              success: response.data.success,
              message: response.data.message,
              show: true,
            });
            if (response.data.success) {
              resolve(response.data);
            }
          })
          .catch(function(err){
            checkAuthorized(err,router);
          })
      });
    },
    addNotification({ commit }, notification) {
      commit("PUSH_NOTIFICATION", notification);
    },

    login({ commit }, data) {
      return new Promise((resolve, reject) => {

        axios.post(apiURL + 'api/Account/Login', data)
          .then(response => {
            if (response.data.success) {
              commit("SET_TOKEN", response.data.token);
              commit("LOAD_USER", decode(response.data.token));
              localStorage.setItem("token", response.data.token);
              router.push("/Empresas").catch(() => { });
              resolve(response.data);
            } else {
              this.dispatch("addNotification", {
                success: response.data.success,
                message: response.data.message,
                show: true,
              });
            }
          })
      });
    },
    autoLogin({ commit }, location) {
      let token = localStorage.getItem("token");
      if (token) {
        commit("SET_TOKEN", token);
        commit("LOAD_USER", decode(token));
        router.push('/Users').catch(() => { });


      }else{
        router.push('/Login').catch(() => { });
      }
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token")
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    roles: (state) => state.usuario.roles.join(", "),
    userName: (state) => state.usuario.usuario,
    userId: (state) => state.usuario.id,
  },
  modules: {
  }
})
