<template >
  <div>
    <app-bar-component>
      <template v-slot:title>
        <v-chip color="white" pill large v-if="selectedEmp.length > 0">
          <v-btn icon left small @click="selectedEmp = []">
            <v-icon> mdi-close</v-icon>
          </v-btn>
          <span class="ml-2 normal_text-1">
            {{ selectedEmp.length }} Seleccionados
          </span>
        </v-chip>
        <span class="bold_text-1" v-else>Empresas</span>
      </template>
      <template v-slot:action>
        <v-btn color="white" fab v-if="selectedEmp.length > 0" @click="removeItem">
          <v-icon color="blue-grey darken-2">mdi-delete</v-icon>
        </v-btn>
        <v-btn color="primary" fab v-else @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </app-bar-component>
    <div class="custom_container" style="overflow-x: auto">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%">
              <v-checkbox
                class="center"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Empresa</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Es empresa</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Dirección</span>
            </th>
            <th class="text-left">
              <span class="normal_text-1">Teléfono</span>
            </th>
            <th class="text-left"><span class="normal_text-1">Estado</span></th>
            <th class="text-left">
              <span class="normal_text-1">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="empresas.length == 0">
              <td colspan="7" class="text-center">
                  <span class="normal_text darken-2--text">** No hay empresas registradas **</span>
              </td>
          </tr>
          <tr v-for="(item, index) in empresas" :key="index">
            <td>
              <v-checkbox
                color="primary"
                v-model="selectedEmp"
                :value="item.empresaId"
              ></v-checkbox>
            </td>
            <td>
              <div class="d-flex">
                <v-avatar>
                  <img
                    :src="$urlBase+'api/Archivos/Descargar/'+item.logoArchivoId+'?v='+ random()"
                  />
                </v-avatar>
                <div class="ml-2">
                  <span class="normal_text darken-2--text">{{
                    item.nombre
                  }}</span>
                  <span class="text-2 blue-grey--text">{{ item.rnc }}</span>
                </div>
              </div>
            </td>
            <td>
              <v-chip
                class="ma-2 white--text"
                color="blue accent-4"
                v-if="item.esEmpresa"
              >
                Sí
              </v-chip>
              <v-chip class="ma-2" color="blue-grey lighten-5" v-else>
                No
              </v-chip>
            </td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.telefono }}</td>
            <td>
              <v-chip
                class="ma-2 white--text"
                color="blue accent-4"
                v-if="item.estadoId == 1"
              >
                {{ item.estadoNombre }}
              </v-chip>
              <v-chip class="ma-2" color="blue-grey lighten-5" v-else>
                {{ item.estadoNombre }}
              </v-chip>
            </td>
            <td>
              <v-menu bottom left offset-x rounded="xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="editItem(item.empresaId)">
                      <v-list-item-icon>
                        <v-icon> mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="getUsers(item.empresaId)">
                      <v-list-item-icon>
                        <v-icon> mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Usuarios</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item v-if="item.estadoId == 5" @click="aprobar(item.empresaId)">
                      <v-list-item-icon>
                        <v-icon> mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Aprobar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item v-if="item.estadoId == 2" @click="aprobar(item.empresaId)">
                      <v-list-item-icon>
                        <v-icon> mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Activar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="removeItem(item.empresaId)">
                      <v-list-item-icon>
                        <v-icon> mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0 text-center">
                  <div>
                    <v-avatar size="160">
                      <img
                        contain
                        :src="srcUrl"
                        alt="John"
                      />
                    </v-avatar>
                  </div>
                  <div>
                    <v-btn
                      color="primary"
                      rounded
                      class="mt-1 ml-1"
                      :loading="isSelecting"
                      @click="onButtonClick"
                    >
                      <v-icon class="mr-1"> mdi-cloud-upload </v-icon>
                      {{ buttonText }}
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    label="Nombre de la empresa"
                    v-model="formData.nombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.rnc"
                    label="RNC"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.direccion"
                    label="Dirección"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.telefono"
                    label="Teléfono"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-select
                    :items="planes"
                    label="Plan"
                    item-value="planId"
                    item-text="planNombre"
                    outlined
                    v-model="formData.planId"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0 pt-0">
                  <v-select
                    :items="comprobantes"
                    label="Tipo de comprobante"
                    item-value="tipoComprobanteId"
                    item-text="comprobanteNombre"
                    outlined
                    v-model="formData.tipoComprobanteId"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0 pt-0">
                  <v-checkbox
                    value="true"
                    v-model="formData.facturarConValorFiscal"
                  >
                    <template v-slot:label>
                      <div class="text-1 blue-grey--text">
                        Requiere valor fiscal
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-checkbox value="true" v-model="formData.esEmpresa">
                    <template v-slot:label>
                      <div class="text-1 blue-grey--text">Es empresa</div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import IconWithBackground from "../components/IconWithBackground.vue";
import router from "../router/index";
import { mapState } from "vuex";

export default {
  name: "Empresas",
  data: () => ({
    formDialog: false,
    dialogTitle: "",
    selectedFile: null,
    isSelecting: false,
    src:"",
    formData: {
      empresaId: 0,
      planId: 0,
      estadoId: 0,
      esEmpresa: false,
      nombre: "",
      rnc: "",
      direccion: "",
      telefono: null,
      facturarConValorFiscal: false,
      tipoComprobanteId: 0,
    },
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    selectedEmp: [],
  }),
  methods: {
    // url(logoArchivoId){
      
    //   return this.$urlBase+'api/Archivos/Descargar/'+item.logoArchivoId+'?'v='+ this.random();
    // },
    addItem() {
      let vm = this;

      vm.dialogTitle = "Agregar empresa";

      vm.selectedFile = null;
      vm.src ="";

      vm.formData = {
        empresaId: 0,
        planId: 0,
        estadoId: 0,
        esEmpresa: false,
        nombre: "",
        rnc: "",
        direccion: "",
        telefono: null,
        facturarConValorFiscal: false,
        tipoComprobanteId: 0,
      };
      vm.formDialog = true;
    },
     random(){
        return Math.random();
    },
    getUsers(id){
        router.push({ path: 'users', query: { id: id }})
    },
    editItem(id) {
      let vm = this;
      vm.selectedFile = null;
      vm.src ="";
      vm.dialogTitle = "Editar empresa";
      vm.getData();

      let e = vm.empresas.find((x) => x.empresaId === id);
      vm.src = this.$urlBase + "api/Archivos/Descargar/" + e.logoArchivoId;
      vm.formData = {
        empresaId: e.empresaId,
        planId: e.planId,
        estadoId: 0,
        esEmpresa: e.esEmpresa + "",
        nombre: e.nombre,
        rnc: e.rnc,
        direccion: e.direccion,
        telefono: e.telefono,
        facturarConValorFiscal: e.facturarConValorFiscal + "",
        tipoComprobanteId: e.tipoComprobanteId,
      };
      vm.formDialog = true;
    },
    removeItem(id) {
      let vm = this;

      let data = {
        id : id
      };

      vm.$store.dispatch("Desactivar_Empresa", data).then((d) => {
        if (d.success) {
          vm.getAllEmpresas();
         
        }
      });
    },
    aprobar(id) {
      let vm = this;

      let data = {
        id : id
      };

      vm.$store.dispatch("Aprobar_Empresa", data).then((d) => {
        if (d.success) {
          vm.getAllEmpresas();
         
        }
      });
    },
    submit() {
      let vm = this;

      var data = new FormData();
      var json = JSON.stringify(vm.formData);
      data.append("json", json);
      data.append("Image", vm.selectedFile)
      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("Agregar_Empresa", data).then((d) => {
          if (d.success) {
            vm.getAllEmpresas();
            vm.formDialog = false;
          }
        });
        vm.formDialog = false;
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.src = URL.createObjectURL(this.selectedFile);
      
    },
    getAllEmpresas() {
      this.$store.dispatch("GET_Empresas");
    },

    getData() {
      this.$store.dispatch("GET_ROLES");
      this.$store.dispatch("GET_Planes");
      this.$store.dispatch("GET_Comprobantes");
    },
  },
  mounted() {
    this.getAllEmpresas();
    this.getData();
    
  },
  computed: {
    ...mapState(["empresas", "planes", "comprobantes"]),
    
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.nombreBtn;
    },
    srcUrl(){
        return this.src? this.src : require('../assets/Imagen no disponible.png');
    },
    selectAll: {
      get: function () {
        return this.empresas
          ? this.selectedEmp.length == this.empresas.length
          : false;
      },
      set: function (value) {
        var selectedEmp = [];

        if (value) {
          this.empresas.forEach(function (emp) {
            selectedEmp.push(emp.empresaId);
          });
        }

        this.selectedEmp = selectedEmp;
      },
    },
  },
  components: {
    AppBarComponent,
    IconWithBackground,
  },
};
</script>
<style >
.custom_table > div > table > thead > tr {
  border-radius: 50px !important;
}

.v-input--selection-controls .v-radio > .v-label {
  font-size: 1.18rem !important;
  color: #607d8b !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #607d8b !important;
  font-size: 1.1rem !important;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 6px;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0px 1rem;
  width: 100%;
  border: 0px #ddd;
}
.custom-table > tbody > tr {
  background-color: white;
  padding-bottom: 10px;
}
.custom-table > thead > tr > th {
  padding: 0px 16px;
}
.custom-table > tbody > tr > td {
  padding: 18px 16px;
}
.custom-table > tbody > tr > td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.custom-table > tbody > tr > td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>