<template >
  <div >
    <div class=" mt-2 mb-2 " :class="noMargin?'pt-0 pb-0':'pt-3 pb-3'">
      <div class="d-flex justify-space-between">
        <span class="text normal_text">{{ title }}</span>
        <span class="text normal_text">{{ value }}%</span>
      </div>
      <div class="pt-2 ">
        <v-progress-linear
          v-model="value"
          :color="color"
          height="12"
          rounded
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    title: String,
    value: Number,
    color: String,
    noMargin:Boolean,
  },
};
</script>
<style scope>

</style>