<template >
  <div class="custom_container">
    <app-bar-component>
      <template v-slot:title>
        <span class="bold_text-1">Calendar</span>
      </template>
      <template v-slot:action>
        <v-btn color="primary" fab @click="formDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </app-bar-component>
    <div>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat rounded class="rounded-t-xl">
              <span class="bold_text-2" v-if="$refs.calendar">
                <v-icon color="blue-grey darken-2">mdi-calendar</v-icon>

                {{ $refs.calendar.title }}
              </span>
              <v-spacer></v-spacer>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn text class="mr-4" color="blue accent-4" @click="setToday">
                Today
              </v-btn>

              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey darken-2"
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="700">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
              class="custom_calendar"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="formDialog" persistent max-width="600px">
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="normal_text">Add Event</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    label="Title"
                    v-model="formData.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formData.description"
                    label="Description"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <DateTimeComponent
                    :label="'Start Date'"
                    :outlined="true"
                    :showIcon="false"
                    :required="true"
                    v-model="formData.startDate"
                  />
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <DateTimeComponent
                    :label="'End Date'"
                    :outlined="true"
                    :showIcon="false"
                    :required="true"
                    v-model="formData.endDate"
                  />
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <span class="text-1 blue-grey--text">Color</span>
                  <v-radio-group
                    v-model="formData.color"
                    row
                    :rules="[rules.required]"
                  >
                    <v-radio value="primary" color="primary" class="radio-blue">
                    </v-radio>
                    <v-radio
                      value="warning"
                      color="warning"
                      class="radio-orange"
                    >
                    </v-radio>
                    <v-radio value="red" color="red" class="radio-red">
                    </v-radio>
                    <v-radio
                      value="success"
                      color="success"
                      class="radio-green"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="formDialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import AppBarComponent from "../components/AppBarComponent.vue";
import DateTimeComponent from "../components/DateTimeComponent.vue";

export default {
  name: "Calendar",
  data: () => ({
    formDialog: false,
    formData: {
      id: 0,
      title: "",
      description: "",
      startDate: null,
      endDate: null,
      color: "",
    },
    rules: {
      required: (value) => !!value || "Field is required.",
    },
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    addItem() {},
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  components: {
    AppBarComponent,
    DateTimeComponent,
  },
};
</script>
<style>
.v-calendar-weekly .v-calendar-weekly__head-weekday {
  font-size: 1em;
  color: #455a64 !important;
  font-weight: 700;
  text-transform: none;
}
.v-calendar-weekly .v-calendar-weekly__head-weekday.v-present {
  color: #1976d2 !important;
}
.v-calendar-weekly__day-label {
  text-align: right !important;
}
.v-calendar-weekly__day-label .v-btn {
  color: rgb(96, 125, 139);
  font-size: 14px;
  font-weight: 800;
  padding: 12px;
}
.radio-blue .v-icon {
  color: #1a76d2;
}
.radio-orange .v-icon {
  color: #fb8c01;
}
.radio-red .v-icon {
  color: red;
}
.radio-green .v-icon {
  color: #4caf50;
}

.v-calendar-weekly__day.v-past.v-outside .v-calendar-weekly__day-label .v-btn,
.v-calendar-weekly__day.v-future.v-outside .v-calendar-weekly__day-label .v-btn {
  color: #617e8a80 !important;
}
.v-calendar-weekly__day.v-past.v-outside,.v-calendar-weekly__day.v-future.v-outside {
  background-color: white !important;
}
.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside
 {
  background-color: #ffffff;
}
</style>