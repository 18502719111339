<template >
  <div>
    <v-card elevation="0" class="rounded-xl mb-4" :color="element.cardC">
      <div class="d-flex pt-6 align-center" :class="noPadding?'pl-0 pr-0 pb-0':'pa-6'">
        <v-avatar size="45" v-if="element.url != null" class="mr-4">
          <v-img :src="element.url"></v-img>
        </v-avatar>
        <div
          class="icon-bground mr-4"
          :style="{ 'background-color': element.fBackg }"
          v-else
        >
          <v-icon :color="element.fColor">{{ element.fIcon }}</v-icon>
        </div>
        <div v-if="element.title != null">
          <span class="card-text">{{ element.title }}</span>
          <span v-if="element.subtitle != null" class="card-text subtitle">{{
            element.subtitle
          }}</span>
        </div>
        <div v-else class="fit_width">
          <slot name="body"></slot>
        </div>

        <div class="ml-auto">
          <slot name="action"></slot>
          <v-icon :color="element.sColor" v-if="element.sIcon">{{
            element.sIcon
          }}</v-icon>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import ProgressLinearComponent from "../Home/ProgressLinearComponent.vue";

export default {
  data: () => ({}),
  computed: {},
  props: {
    element: Object,
    noPadding:Boolean
  },
  components: {
    ProgressLinearComponent,
  },
};
</script>
<style scoped>
.fit_width {
  flex: 1 1 auto !important;
}
.icon-bground {
  border-radius: 50%;
  /* background-color: rgb(88, 88, 194); */
  padding: 13px;
  width: 50px;
  height: 50px;
}
.card-text {
  margin: 0px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.6;
  letter-spacing: 0px;
  display: block;
  color: rgb(69, 90, 100);
}
.subtitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgb(96, 125, 139);
}
</style>