<template >
  <div>
    <v-card elevation="0" class="rounded-xl mb-4" :style="estiloCard">
      <div class="d-flex pa-6" :class="clases">
        <slot name="header"></slot>
        <div class="chart-container" :id="'chart-c' + id">
          <slot name="body"></slot>
        </div>
        <slot name="action"> </slot>
        <!-- <div class="subtitle pb-4">Views</div>
        <div class="card-text">6.967.431</div> -->
        <!-- <div class="chart-container">
          <LineChart :chartdata="data" :options="options" />
        </div> -->
        <!-- <div class="mt-10">
          <followers-component :element="object">
            <v-btn icon >
              <v-icon color="#455a64">mdi-chevron-right</v-icon>
            </v-btn>
          </followers-component>
        </div> -->
      </div>
    </v-card>
  </div>
</template>
<script>
// import { Line } from "vue-chartjs";
import LineChart from "../LineChart.js";

export default {
  data() {
    return {};
  },
  mounted() {
    let vm = this;
    if (vm.id != undefined) {
      var container = document.getElementById("chart-c" + vm.id);
      container.firstChild.lastChild.style.maxHeight = vm.maxHeight;
      container.firstChild.lastChild.style.maxWidth = vm.maxWidth;
    }
  },
  props: {
    maxHeight: String,
    maxWidth: String,
    estiloCard: String,
    id: Number,
    clases: String,
  },
  components: {
    LineChart,
  },
};
</script>
<style >
</style>
