<template>
  <v-app style="background: #eceff1">
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.width >= breackpoint"
      :temporary="temporal"
      :disable-route-watcher="true"
      :disable-resize-watcher="true"
      floating
      v-model="drawer"
      width="300"
      app
      v-if="this.$route.name != 'Login'"
    >
      <div class="logo-container">
        <v-img
          contain
          lazy-src="https://picsum.photos/id/11/10/6"
          max-height="150"
          max-width="78"
          src="./assets/logo.png"
        ></v-img>
      </div>
      <v-list color="455a64" rounded>
        <v-list-item-group class="selected-group">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.url" link>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list color="455a64" rounded flat>
          <v-list-item-group class="selected-group">
            <v-list-item to="/Profile">
              <v-list-item-icon>
                <div class="icon-bground">
                  <v-icon color="#455a64">mdi-account</v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-title>{{ userName }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="settingsDrawer = !settingsDrawer">
              <v-list-item-icon>
                <div class="icon-bground">
                  <v-icon color="#455a64">mdi-cog</v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item> -->
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="settingsDrawer"
      absolute
      temporary
      width="300"
    >
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              <span class="bold_text-2">Settings</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="settingsDrawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div class="pl-4 pr-4">
        <span class="normal_text-0">Mode</span>
        <div class="button_group rounded-lg">
          <v-btn-toggle
            v-model="isDark"
            color="#728B98"
            borderless
            rounded
            class="rounded-lg"
          >
            <v-btn :value="false" color="white" x-large block>
              <span class="normal_text-1 text-none">Light</span>
            </v-btn>

            <v-btn :value="true" color="white" x-large block>
              <span class="normal_text-1 text-none">Dark</span>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view class="main-child" />
      <NotificationMessage />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import NotificationMessage from "./components/NotificationMessage";
import router from "./router/index";

export default {
  name: "App",

  data: () => ({
    //
    drawer: true,
    settingsDrawer: false,
    breackpoint: 1280,
    isDark: false,
    items: [
      // { text: "Home", icon: "mdi-home", url: "/" },
      { text: "Usuarios", icon: "mdi-account-multiple", url: "/Users" },
      { text: "Empresas", icon: "mdi-domain", url: "/Empresas" },
      { text: "Oposiciones", icon: "mdi-information", url: "/Oposiciones" },
      { text: "Lugares", icon: "mdi-map-marker", url: "/Lugares" },
      { text: "Reportes", icon: "mdi-alert-octagon", url: "/Reportes" },
      { text: "Balance", icon: "mdi-poll", url: "/Dashboard" },
      // { text: "Calendar", icon: "mdi-calendar", url: "/Calendar" },
      // { text: "Project", icon: "mdi-sitemap", url: "/Project" },
      // { text: "Help", icon: "mdi-help-box", url: "/Help" },
    ],
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userName: function () {
      return this.$store.getters.userName;
    },
    temporal() {
      let result = this.$vuetify.breakpoint.width < this.breackpoint;
      this.drawer = !result;
      return result;
    },
  },
  methods:{
    IsAuth(){
      console.log("Hellopeee");
    }
  },
  watch: {
    drawer: function (val) {
      this.$eventHub.$emit("drawer-value", val);
    },
  },
  created() {

   

    let vm = this;

    router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!this.isLoggedIn) {
          next({ name: "Login" });
        } else {
          next(); // go to wherever I'm going
        }
      } else {
        next(); // does not require auth, make sure to always call next()!
      }
    });

    this.$store.dispatch("autoLogin");

    vm.$eventHub.$on("drawer-toogle", (data) => {
      vm.drawer = data;
    });
  },
  components: {
    NotificationMessage,
  },
};
</script>

<style >
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.v-application {
  font-family: "Nunito", sans-serif !important;
}
.icon-bground {
  border-radius: 50%;
  background-color: #eceff1;
  padding: 8px;
}
.logo-container {
  display: flex;
  padding: 32px;
  height: 104px;
}

.button_group {
  background-color: #eceff1;
  padding: 5px;
  width: 242px;
}
@import "./assets/styles/main.css";
</style>
