import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Users from '../views/Users.vue'
import Calendar from '../views/Calendar.vue'
import Project from '../views/Project.vue'
import Help from '../views/Help.vue'
import Profile from '../views/Profile.vue'
import Empresas from '../views/Empresas.vue'
import Lugares from '../views/Lugares.vue'
import Reportes from '../views/Reportes.vue'
import Oposiciones from '../views/Oposiciones.vue'
import Login from '../components/Login.vue'
import Movimientos from '../views/Movimientos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Empresas',
    name: 'Empresas',
    component: Empresas,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Lugares',
    name: 'Lugares',
    component: Lugares,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Oposiciones',
    name: 'Oposiciones',
    component: Oposiciones,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Project',
    name: 'Project',
    component: Project,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Reportes',
    name: 'Reportes',
    component: Reportes
  },
  {
    path: '/Help',
    name: 'Help',
    component: Help,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Movimientos',
    name: 'Movimientos',
    component: Movimientos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


export default router
